import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { AppTargetEntity } from "./models/appTarget";
import { ApplicationService } from "./services/application.service";
import { BusinessUnitEntity } from "./models/unit";
import { LocalstorageService } from "../local.storage.service";
import { SharedAnimations } from "./animations/shared-animations";

@Component({
  selector: 'master-comp',
  template: ``,
  animations: [SharedAnimations]
})
export class ComponentBase implements OnInit, OnDestroy {

  public localStorageService: LocalstorageService;

  public _unsubscribeAll = new Subject();
  public _appService: ApplicationService;

  public currentUnit: BusinessUnitEntity;
  public appTarget: AppTargetEntity;
  public isSmallMobile: boolean = false;

  constructor() {
    this._appService = inject(ApplicationService);
    this.localStorageService = inject(LocalstorageService);
  }

  ngOnInit()  {
    this._appService.onSmallMobile$.pipe(takeUntil(this._unsubscribeAll)).subscribe(isSmall => {
      this.isSmallMobile = isSmall;
    });

    this._appService.unitService.onItemLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      this.currentUnit = resp;
    });

    this._appService.appService.onItemLoadEvent$.pipe(takeUntil(this._unsubscribeAll)).subscribe(resp => {
      if(resp && (resp?.id != this.appTarget?.id)) {
        this.appTarget = resp;
      }

    });

  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();

  }

}
